<script setup lang="ts"></script>

<template>
    <svg
        width="18"
        height="18"
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <g clip-path="url(#clip0_308_561)">
            <path
                d="M18 9.01081C18 4.81681 14.9893 1.07513 10.8895 0.197801C8.84101 -0.240519 6.6627 0.054756 4.80912 1.03265C3.02525 1.97381 1.58352 3.51677 0.766458 5.36281C-0.0836261 7.28301 -0.231766 9.48747 0.347951 11.5057C0.907031 13.4528 2.13412 15.1792 3.78131 16.3546C7.22499 18.812 12.0622 18.4835 15.148 15.5923C16.9564 13.8978 18 11.491 18 9.01081ZM8.51835 13.3341L6.00548 10.8773C5.36912 10.2553 6.33317 9.2721 6.96655 9.89158L8.23446 11.1312L8.23446 5.2565C8.23446 4.83931 8.58233 4.49122 8.99878 4.49122C9.41522 4.49122 9.7631 4.83954 9.7631 5.2565L9.7631 11.1018L11.0255 9.83762C11.6529 9.20942 12.6259 10.1834 11.9985 10.8116L9.48516 13.3283C9.21892 13.5951 8.7878 13.5977 8.51835 13.3341Z"
                fill="currentColor"
            />
        </g>
        <defs>
            <clipPath id="clip0_308_561">
                <rect
                    width="18"
                    height="18"
                    fill="white"
                    transform="translate(18) rotate(90)"
                />
            </clipPath>
        </defs>
    </svg>
</template>
